import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  OfficeBuildingIcon,
  ChartPieIcon,
  UsersIcon,
  MenuIcon,
  MailIcon,
  PlayIcon,
  XIcon,
} from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import Link from "next/link";
import Image from "next/image";

const legalTools = [
  {
    name: "Incorporation",
    description:
      "Everything you need to form your startup following best practices.",
    href: "/tools/incorporation",
    icon: OfficeBuildingIcon,
  },
  {
    name: "Founder Shares",
    description:
      "Issue equity to your startup's founders and handle your 83(b) Election.",
    href: "/tools/founder-shares",
    icon: UsersIcon,
  },

  {
    name: "Equity Management",
    description:
      "Fundraise and hire with our equity management tools. Your cap table syncs seamlessly.",
    href: "/tools/equity-management",
    icon: ChartPieIcon,
  },
];
const callsToAction = [
  { name: "Begin Now", href: "/register", icon: PlayIcon },
  { name: "Get Support", href: "mailto:support@symbo.la", icon: MailIcon },
];

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(" ");
};

interface HeaderProps {
  lightMode: boolean;
}

const Header: React.FC<HeaderProps> = ({ lightMode }) => {
  return (
    <Popover
      className={classNames(lightMode ? "bg-white" : "bg-gray-900", "relative")}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex justify-between items-center  py-4 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link passHref href="/">
              <Image
                className="cursor-pointer"
                height={48.3}
                width={38.5}
                src="/logoSymbola_blue500.png"
                alt="Symbola logo"
              />
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button
              className={classNames(
                lightMode
                  ? "bg-white   hover:text-gray-500 hover:bg-gray-100  focus:ring-blue-500"
                  : "bg-gray-900    hover:bg-gray-800  focus:ring-white",
                "text-gray-400 rounded-md p-2 inline-flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-inset"
              )}
            >
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden md:flex space-x-10">
            <Popover className="relative">
              {({ open }) => (
                <>
                  {lightMode ? (
                    <Popover.Button
                      className={classNames(
                        open ? "text-gray-900" : "text-gray-500",
                        "group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      )}
                    >
                      <span>Legal Tools</span>
                      <ChevronDownIcon
                        className={classNames(
                          open ? "text-gray-600" : "text-gray-400",
                          "ml-2 h-5 w-5 group-hover:text-gray-500"
                        )}
                        aria-hidden="true"
                      />
                    </Popover.Button>
                  ) : (
                    <Popover.Button
                      className={classNames(
                        open ? "text-gray-300" : "text-white",
                        "group  rounded-md inline-flex items-center text-base font-medium hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-gray-900 focus:ring-offset-2 focus:ring-blue-500"
                      )}
                    >
                      <span>Legal Tools</span>
                      <ChevronDownIcon
                        className={classNames(
                          open ? "text-gray-500" : "text-gray-300",
                          "ml-2 h-5 w-5 group-hover:text-gray-400"
                        )}
                        aria-hidden="true"
                      />
                    </Popover.Button>
                  )}

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-40 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                          {legalTools.map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                            >
                              <item.icon
                                className="flex-shrink-0 h-6 w-6 text-blue-600"
                                aria-hidden="true"
                              />
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">
                                  {item.name}
                                </p>
                                <p className="mt-1 text-sm text-gray-500">
                                  {item.description}
                                </p>
                              </div>
                            </a>
                          ))}
                        </div>
                        <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                          {callsToAction.map((item) => (
                            <div key={item.name} className="flow-root">
                              <a
                                href={item.href}
                                className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100"
                              >
                                <item.icon
                                  className="flex-shrink-0 h-6 w-6 text-gray-400"
                                  aria-hidden="true"
                                />
                                <span className="ml-3">{item.name}</span>
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
            <Link href="/pricing">
              <a
                className={classNames(
                  lightMode
                    ? "text-gray-500 hover:text-gray-900"
                    : "text-white hover:text-gray-300",
                  "text-base font-medium"
                )}
              >
                Pricing
              </a>
            </Link>
            <Link href="/support">
              <a
                className={classNames(
                  lightMode
                    ? "text-gray-500 hover:text-gray-900"
                    : "text-white hover:text-gray-300",
                  "text-base font-medium"
                )}
              >
                Support
              </a>
            </Link>
          </Popover.Group>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <Link href="/signin">
              <a
                className={classNames(
                  lightMode
                    ? "whitespace-nowrap text-gray-500 hover:text-gray-900"
                    : " text-white hover:text-gray-300",
                  "font-medium text-base mr-6"
                )}
              >
                Log in
              </a>
            </Link>
            <Link href="/register">
              <a
                className={classNames(
                  lightMode
                    ? "whitespace-nowrap bg-blue-600 justify-center  text-white hover:bg-blue-700"
                    : "  text-white bg-gray-600 hover:bg-gray-700",
                  "inline-flex items-center text-base font-medium px-4 py-2  rounded-md border border-transparent "
                )}
              >
                Sign up
              </a>
            </Link>
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute top-0 z-20 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <Image
                    height={31.05}
                    width={24.75}
                    src="/logoSymbola_blue500.png"
                    alt="Symbola logo"
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {legalTools.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                    >
                      <item.icon
                        className="flex-shrink-0 h-6 w-6 text-blue-600"
                        aria-hidden="true"
                      />
                      <span className="ml-3 text-base font-medium text-gray-900">
                        {item.name}
                      </span>
                    </a>
                  ))}
                </nav>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                <Link href="/pricing">
                  <a className="text-base font-medium text-gray-900 hover:text-gray-700">
                    Pricing
                  </a>
                </Link>
                <Link href="/support">
                  <a className="text-base font-medium text-gray-900 hover:text-gray-700">
                    Support
                  </a>
                </Link>
              </div>
              <div>
                <Link href="/register">
                  <a className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700">
                    Sign up
                  </a>
                </Link>

                <p className="mt-6 text-center text-base font-medium text-gray-500">
                  Already Registered?{" "}
                  <Link href="/signin">
                    <a className="text-blue-600 hover:text-blue-500">Sign in</a>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
export default Header;
